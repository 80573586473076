import ReactDOM from 'react-dom/client';

import AppRouter from './routes/router';
import './assets/styles/style.scss';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<AppRouter/>
);