import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import MainView from '../pages/MainView';


const AppRouter = () => {	

	return (
		<Router>
			<Routes>
				<Route path="/" element={<MainView />} />
				<Route path="*" element={<Navigate to={'/'} />}/>
			</Routes>
		</Router>
  	);
}

export default AppRouter; 