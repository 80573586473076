import { Download, QuestionAnswer } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import VideoPlayer from "react-background-video-player";

import tcLogo from "../assets/images/logo-tc.svg";


import { IconButton } from "../components/IconButton";

const MainView = () => {

    return (
        <Container>
           
            <Grid item xs={12} container className="sections-container">
                <VideoPlayer
                    className="video"
                    src={"/TC.mp4"}
                    autoPlay={true}
                    muted={true}
                />
                <div className="center-elements">
                    <img  src={tcLogo} alt="logo-tc" />
                </div>
                <div className="bottom-elements">    
                    <h2>SITE EN CONSTRUCTION</h2>
                </div>
                
            </Grid>
        </Container>
    )
}

export default MainView;